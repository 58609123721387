








import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
// import { datadogRum } from '@datadog/browser-rum';
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from '@/lib/auth';

@Component({
  components: {},
})
export default class App extends Vue {




  async beforeCreate() {
    // if (isMobile && !isProd()) {
    // window.location.href = getConfigEnv('MOBILE_SITE');
    // }

  }

  async created() {
    if (!this.$route.meta.skipAuth && getAccessToken()) {
      if (getAccessToken() && isTokenExpired()) {
        await refreshAccessToken();
      }
      // await accountModule.getReassignmentList({useExisting: true});
    }




		// datadogRum.init({
		// 	applicationId: '42a5cbbe-cee7-4b16-b31c-27f8330f507b',
		// 	clientToken: 'pubb848c6a76f8811fde5cd24dc0264f13f',
		// 	site: 'datadoghq.com',
		// 	service:'tasq',
		// 	env:'dev',
		// 	// Specify a version number to identify the deployed version of your application in Datadog
		// 	// version: '1.0.0',
		// 	sampleRate: 100,
		// 	premiumSampleRate: 100,
		// 	trackInteractions: true,
		// 	defaultPrivacyLevel:'allow'
		// });

		// datadogRum.startSessionReplayRecording();

  }

  async mounted() {
	if (!accountModule.didPreloadData) {
		// preloadData()
	}
}
}
