import VueTailwind from 'vue-tailwind';
import { VueConstructor } from 'vue';
import {
  TDatepicker,
  TToggle,
  TInput,
  TTextarea,
  TRadio,
  TRadioGroup,
  TCheckbox,
  TTag,
  TCard,
  TCheckboxGroup,
  TSelect,
  TDialog,
  TDropdown,
  TRichSelect,
  TAlert,
} from 'vue-tailwind/dist/components';

const components = {
  TDatepicker,
  TToggle,
  TInput,
  TTextarea,
  TDropdown,
  TCard: {
    component: TCard,
    props: {
      fixedClasses: {
        wrapper: 'rounded shadow-sm',
        body: 'p-3 rounded shadow-sm border-solid border border-white  border-opacity-10',
        header: 'border-b p-3 rounded-t',
        footer: 'border-t p-3 rounded-b'
      },
      classes: {
        wrapper: 'bg-gray-700 ',
        body: 'rounded shadow-sm border-solid border border-white  border-opacity-10 text-white ',
        header: 'border-gray-100',
        footer: 'border-gray-100'
      },
      variants: {
        danger: {
          wrapper: 'bg-red-50 text-red-700 border-red-200',
          header: 'border-red-200 text-red-700',
          footer: 'border-red-200 text-red-700'
        }
      }
    }
  },
  TRadio,
  TTag: {
    component: TTag,
    props: {
      fixedClasses: '',
      classes: '',
      variants: {
        title: 'text-2xl leading-8 font-extrabold text-gray-900 tracking-tight',
        subtitle: 'text-lg leading-6 font-medium text-gray-900',
        error: 'text-red-500',
        badge: 'inline-flex items-center px-3 rounded-full text-xs py-1 font-medium leading-4 bg-red-100 text-red-600',
        avatar: 'inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 overflow-hidden leading-none text-center'
      },
    },
  },
  TRadioGroup: {
    component: TRadioGroup,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col mb-2 text-sm justify-start ',
        input: 'mr-2 bordered-radio text-green-500',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    },
  },
  TCheckbox,
  TCheckboxGroup: {
    component: TCheckboxGroup,
    props: {
      classes: {
        wrapper: 'grid grid-flow-col justify-start mb-2',
        input: 'mr-2 bordered-radio rounded-full',
      },
      variants: {
        error: {
          input: 'text-red-500 transition duration-100 ease-in-out',
          label: 'bg-red-100 text-red-500',
        },
      },
    },
  },
  TSelect,
  TDialog,
  TRichSelect,
  TAlert: {
    component: TAlert,
    props: {
      fixedClasses: {
        wrapper: 'fixed flex items-center p-4 border-l-4  rounded shadow-sm',
        body: 'flex-grow',
        close: 'absolute relative flex items-center justify-center ml-4 flex-shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        closeIcon: 'fill-current h-4 w-4'
      },
      classes: {
        wrapper: 'bg-white border-cyan',
        body: 'text-cyan',
        close: 'text-charcoal'
      },
    }
  }
};

export default function installVueTailwind(Vue: VueConstructor) {
  Vue.use(VueTailwind, components);
}
