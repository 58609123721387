import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from '@/lib/auth';
import accountModule from '@/store/modules/accountModule';
import routes from './routes';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // @ts-ignore
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/',
});

router.beforeEach(async (to, from, next) => {
  // Init auth
  if (!to.meta.skipAuth) {
    // If token exists and it is expired - refresh it
    if (getAccessToken() && isTokenExpired()) {
      await refreshAccessToken();
    }
    if (!getAccessToken()) {
      return next({ name: 'Login' });
    }
  }

  // Check if user is allowed to see some page
  if (!accountModule.user?.id) {
    await accountModule.setUser();
  }
  const { user } = accountModule;
  const { groups } = user;
  // if (to.meta.disabled || !(to.meta.allowedFor?.some((r) => groups?.includes(r)))) {
  //   if ((!to.meta.allowedForAll && !user.isAdmin) || to.meta.disabled) {
  //     return next({ name: '404' });
  //   }
  // }

  // Normalize title
  let title = 'Tasq Desktop';
  if (to.meta.title) {
    title = typeof to.meta.title === 'function'
      ? to.meta.title(to) : to.meta.title;
  }
  document.title = title;

  // Force SW update
  if (window.navigator.serviceWorker) {
    window.navigator.serviceWorker.getRegistrations()
      .then((registrationsArray) => {
        if (registrationsArray[0]) {
          registrationsArray[0].update();
        }
      });
  }
  return next();
});

export default router;
