import './router/componentHooks';
import Vue from 'vue';
import installVue from '@/lib/installVue';
import installVueTailwind from '@/lib/installVueTailwind';
import '@/lib/directives/closable';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.ignoredElements = [/^cq-.*$/, /^stx-.*$/];

Vue.config.productionTip = false;
Vue.component('template-placeholder', {
  render(createElement) {
    return createElement('template', this.$slots.default);
  },
});
// Init Plugins

installVue(Vue);
Vue.use(VueTheMask);
installVueTailwind(Vue);

new Vue({
  router,
  store,
  watch: {
    $route: () => {
      // @ts-ignore
      window.Appcues.page();
    },
  },
  render: (h) => h(App),
}).$mount('#app');
